<template>
  <common :title="title">
    <div class="section-type">
      <div
        class="list"
        v-for="(item, index) in list"
        :key="index"
        @click="redirect(item.url)"
      >
        <img :src="item.icon" alt="" />
        <p>{{ item.text }}</p>
      </div>
    </div>
  </common>
  <!-- <div class="register">
    <div class="register-type">
      <div class="register-title">
        <div class="back" @click="$router.back()">
          <img src="./img/back.png" alt="" />
        </div>
        <span>请选择注册/登录方式</span>
      </div>
      <div class="section-type">
        <div class="list" v-for="(item, index) in list" :key="index">
          <img :src="item.icon" alt="" />
          <p>{{ item.text }}</p>
        </div>
      </div>
    </div>
  </div> -->
</template>

<script>
import storage from "../utils/storage";
import { uploadUrl, aoiLogin } from "./api.js";
import { base64toFile } from "@/utils/utils.js";
import common from "../components/common.vue";
export default {
  name: "register",
  components: {
    common,
  },
  props: {},
  data() {
    return {
      title: "",
      list: [
        {
          icon: require("./img/card.png"),
          text: "身份证",
          url: "identityCardRegister",
        },
        {
          icon: require("./img/face.png"),
          text: "人脸",
          url: "humanFaceRegister",
        },
        {
          icon: require("./img/phone.png"),
          text: "手机号",
          url: "mobilePhoneRegister",
        },
      ],
      isRegister: 0, //0注册 1登录
      faceInfo: {
        localPath: "",
        facePic: "",
      },
    };
  },
  created() {},
  mounted() {
    const { isRegister } = this.$route.query;
    if (![null, undefined, ""].includes(isRegister)) {
      this.isRegister = isRegister;
      this.title = isRegister == 0 ? "请选择注册方式" : "请选择登录方式";
    }
    //获取人脸图片信息
    window.onPhotoTake = this.onPhotoTake;
    //监听人脸成功状态
    window.onFacePassRecognized = this.onFacePassRecognized;
  },
  methods: {
    redirect(name) {
      if (name == "humanFaceRegister") {
        this.takePhoto();
      } else {
        this.$router.push({
          name,
          query: {
            isRegister: this.isRegister,
          },
        });
      }
    },
    //拍照注册，登录
    //拍照获取结果
    async onPhotoTake(localPath, imageBase64) {
      await this.uploadImg(base64toFile(imageBase64));
      this.faceInfo.localPath = localPath;
      storage.set("faceInfo", this.faceInfo);
      this.$router.push({
        name: "faceSubmission",
      });

      // window.sys.nativeSet("facePic", this.faceInfo.facePic);
      // window.sys.nativeSet("localPath", this.faceInfo.localPath);
    },
    //人脸识别结果
    onFacePassRecognized(userId) {
      this.aoiLogin(userId);
    },
    //人脸登录
    aoiLogin(userId) {
      this.$axios.post(aoiLogin, { userId, registerType: 2, deviceNo: storage.get('deviceNo') || '' }).then((res) => {
        if (res.code == 200) {
          const { data } = res;
          storage.set("systemUserInfo", data);
          this.$store.commit("setUserId", data.userId);
          this.$store.commit("setHouseId", data.houseId);
          this.$store.commit("setTenantId", data.tenantId);
          this.$store.commit("setCommunityId", data.communityId);
          this.$router.push({
            name: "guidePage",
          });
        }
      });
    },

    //拍照
    takePhoto() {
      //注册
      if (this.isRegister == 0) {
        window.sys.takePhoto();
      } else {
        window.sys.loginByFacePass();
      }
      // 选择图片
    },
    //
    // 转为图片
    async uploadImg(file) {
      var formData = new FormData();
      formData.append("file", file);
      let res = await this.$axios.post(uploadUrl, formData);
      if (res.code == 200) {
        this.faceInfo.facePic = res.data.link;
      }
    },
  },
};
</script>

<style scoped lang="less">
.section-type {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  // gap: 40px;
  padding-top: 96px;
  padding-bottom: 94px;
  box-sizing: border-box;
  .list {
    height: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: linear-gradient(#1c77ff 0%, #58bbff 100%);
    border-radius: 36px;
    &:not(:last-child) {
      margin-right: 40px;
    }
    &:nth-child(2) {
      background: linear-gradient(180deg, #ffc73c 0%, #f99141 100%);
    }
    &:nth-child(3) {
      background: linear-gradient(180deg, #84e9ba 0%, #00cf96 100%);
    }
    img {
      width: 140px;
      height: 140px;
      margin-bottom: 23px;
    }
    p {
      font-weight: bold;
      font-size: 30px;
      color: #ffffff;
      line-height: 40px;
      font-style: normal;
    }
  }
}
// .register {
//   padding: 39px 24px 55px 39px;
//   box-sizing: border-box;

//   .register-type {
//     height: 587px;
//     display: flex;
//     flex-direction: column;
//     background: #ffffff;
//     border-radius: 15px;
//     box-sizing: border-box;
//     padding: 59px 42px 94px 41px;

//     .register-title {
//       box-sizing: border-box;
//       position: relative;
//       text-align: center;
//       span {
//         text-align: center;
//         font-weight: 600;
//         font-size: 24px;
//         color: #1874fd;
//         line-height: 33px;
//         font-style: normal;
//       }
//       .back {
//         position: absolute;
//         left: 0;
//         top: 50%;
//         transform: translateY(-50%);
//         width: 40px;
//         height: 40px;
//         img {
//           width: 100%;
//           height: 100%;
//         }
//       }
//     }
//   }
// }
</style>
